import fetch from 'isomorphic-unfetch';
import { getSession, getCsrfToken, signOut, useSession } from 'next-auth/client';
import Layout from '../components/layout';
import Router from 'next/router';
import {
  Heading,
  Text,
  Link,
  Button,
  SimpleGrid
} from '@chakra-ui/core';

import SignInForm from '../components/signin-form';
import styles from '../components/logged-in.module.css';

import WithGraphQL from '../lib/with-graphql';
import PageContent from '../components/public-graphql-comp';
import { setCookie } from 'nookies';
import {HN_NOTIFIER_PUBLIC_TOKEN_COOKIE_KEY_NAME} from '../lib/constants';


Router.events.on('routeChangeComplete', (url) => {
  if (process.browser) {
    // Client-side-only code
    if(typeof window !== 'undefined'){
      global.window.analytics.page(url);
    }
  }
});

export default function Page({  csrfToken }) {
  // As this page uses Server Side Rendering, the `session` will be already
  // populated on render without needing to go through a loading stage.
  // This is possible because of the shared context configured in `_app.js` that
  // is used by `useSession()`.
  // eslint-disable-next-line no-unused-vars

  // used to test Sentry
  // throw new Error('test breakin things');
  const [session, loading] = useSession();

  // When rendering client side don't display anything until loading is complete
  // if (typeof window !== 'undefined' && loading) return null;

  return (
    <WithGraphQL session={session}>
      <Layout>
        <Heading as="h1" size="xl" mb={4}>Welcome to HN Notifier!</Heading>
        {!session && <>
          <SignInForm csrfToken={csrfToken}  />
          <Text mt={4}>
        to get <b> realtime(ish) email notifications </b> when someone <b>replies to your comments </b> on  
            <Link ml={2} href="https://news.ycombinator.com/news" isExternal>
          Hacker News!
            </Link>
          </Text>
        </>}
      
        {session && <>
          <SimpleGrid columns={3}>
            <div className={styles.signedInText}>
              <small>Signed in as</small>
              <strong>{session.user.email || session.user.name}</strong>
            </div>
            <div>
              <Button>
                <Link href="/protected"> Profile </Link>
              </Button>

            </div>
            <div>
              <Button
                href={'/api/auth/signout'}
                onClick={(e) => {
                  e.preventDefault();
                  signOut();
                }}
              >
         Sign out
              </Button>
            </div>
          </SimpleGrid>
        </>}

        <PageContent mt={4} session={session} />

      </Layout>
    </WithGraphQL>
  );
}

// Export the `session` prop to use sessions with Server Side Rendering
export async function getServerSideProps(context) {
  // Call an external API endpoint to get posts.
  // You can use any data fetching library
  // https://nextjs.org/docs/api-routes/introduction
  // https://nextjs.org/docs/basic-features/data-fetching
  const anonJWTres = await fetch(`${process.env.NEXTAUTH_URL}/api/auth/anon-jwt`);
  // console.log( 'anonJWTres', anonJWTres);
  const { publicJWT } = await anonJWTres.json();
  // console.log( 'publicJWT', publicJWT);

  // Set
  setCookie(context, HN_NOTIFIER_PUBLIC_TOKEN_COOKIE_KEY_NAME, publicJWT, {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  });

  return {
    props: {
      csrfToken: await getCsrfToken(context),
      session: await getSession(context),
    },
  };
}