import MostRecentHNItem from './public-graphql-sub-comps/most-recent-hn-item';
import Past24HrsHNItemCount from './public-graphql-sub-comps/past-24-hr-hn-item-count';
import {
  SimpleGrid,
  Text
} from '@chakra-ui/core';

export default function Page() {
  return (
    <>
      <SimpleGrid mt={8} columns={[2]} spacing="24px">
        <Past24HrsHNItemCount /> 
        <MostRecentHNItem /> 
      </SimpleGrid>
      <SimpleGrid mt={8} columns={[1]} spacing="24px">
        <Text>  <i>Stats refreshed every couple seconds.</i> <b> Emails sent asap.</b>   </Text>
      </SimpleGrid>
    </>
  );
}
