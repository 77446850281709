import { useState } from 'react';
import { useQuery } from 'urql';
import gql from 'graphql-tag';
import {
  Text,
  Link
} from '@chakra-ui/core';
import * as Sentry from '@sentry/browser';

// sadly we can't use subscriptions becasue websockets are broken through aws elb .. and since we are on eks it uses that in front of ingress-nginx
// https://stackoverflow.com/questions/63132671/web-socket-wss-in-aws-eks-with-nginx-ingress-returns-400-ws-http-and?noredirect=1&lq=1
// https://github.com/kubernetes/ingress-nginx/issues/3746

const pollInterval = 3000; // enough to seem real time 

const hasuraHnItemMaxQuery = gql`
query mostRecentHNItem {
  hn_item(limit: 1, order_by: {id: desc}) {
    id
  }
}
`;

export default function Comp() {

  const [
    // eslint-disable-next-line no-unused-vars
    { data: mostRecentHNItemData, fetching: mostRecentHNItemFetching, error: mostRecentHNItemError },
  ] = useQuery({
    query: hasuraHnItemMaxQuery,
    pollInterval
  });
  
  const [hnItemId, setHnItemId] = useState(false);

  // const [
  //   // eslint-disable-next-line no-unused-vars
  //   { data: past24HrsHNItemData, fetching: past24HrsHNItemFetching, error: past24HrsHNItemError },
  // ] = useQuery({
  //   variables: {
  //     from: new Date(Date.now() - 86400 * 1000).toISOString() // https://stackoverflow.com/questions/11072467/javascript-relative-time-24-hours-ago-etc-as-time
  //   },
  //   query: hasuraHnItemTodayQuery,
  // });
  if(mostRecentHNItemFetching && !hnItemId) return <div> fetching recently processed stuff info</div>; // past24HrsHNItemFetching

  if(mostRecentHNItemError ) { // || past24HrsHNItemError
    // https://stackoverflow.com/questions/60219418/react-js-log-all-graphql-queries-in-sentry

    const {name, message, graphQLErrors, networkError, response} = mostRecentHNItemError;
    
    if (graphQLErrors){
      graphQLErrors.map(({ message, locations, path }) =>
        Sentry.captureMessage(message)
      );
    }

    if (networkError) {
      Sentry.captureException(networkError);
    }

    // lets just show nothing if this erors
    return (<> 
      {/* error getting most recent stuff */}
      {/* {graphQLErrors && <></> <Text fontSize="lg" color="tomato">{mostRecentHNItemError}</Text> } */}
    </>
    );
  }

  const [ hn_item ] = mostRecentHNItemData.hn_item;
  const newId = hn_item && hn_item.id;

  if(hnItemId !== newId){
    setHnItemId(newId);

  }

  const href = `https://news.ycombinator.com/item?id=${hnItemId}`;

  return (
    <Text> Currently Processing Id:  
      <Link ml={2} href={href} isExternal>
        <b> {hnItemId} </b> 
      </Link>
    </Text>
  );
}
