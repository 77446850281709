import {useState} from 'react';
import { useQuery } from 'urql';
import gql from 'graphql-tag';
import {
  Text,
} from '@chakra-ui/core';
import * as Sentry from '@sentry/browser';

// sadly we can't use subscriptions becasue websockets are broken through aws elb .. and since we are on eks it uses that in front of ingress-nginx
// https://stackoverflow.com/questions/63132671/web-socket-wss-in-aws-eks-with-nginx-ingress-returns-400-ws-http-and?noredirect=1&lq=1
// https://github.com/kubernetes/ingress-nginx/issues/3746

const pollInterval = 3000;

// greater thndan 24hrs ago
// https://hasura.io/learn/graphql/vue/subscriptions/
// https://stackoverflow.com/questions/60787554/hasura-graphql-query-between-dates
const hasuraHnItemTodayQuery = gql`

query hnItemToday($from: timestamptz!) {
  hn_item_aggregate(where: {created_at: {_gte: $from}}) {
    aggregate {
      count
    }
  }
}

`;

// DO NOT MOVE THIS INTO THE FUNCTION BELOW
// IT WILL CONSTANTLY RECOMPUTE CAUSING A RERENDER AND INFINITELY LOOPING GRAPHQL REQUESTS AND BLOW UP!!!!!!!!!!!!!!
const loadDate = new Date(Date.now() - 86400 * 1000);

export default function Comp() {

  const [
    // eslint-disable-next-line no-unused-vars
    { data: past24HrsHNItemData, fetching: past24HrsHNItemFetching, error: past24HrsHNItemError },
  ] = useQuery({
    variables: {
      from: loadDate.toISOString() // https://stackoverflow.com/questions/11072467/javascript-relative-time-24-hours-ago-etc-as-time
    },
    query: hasuraHnItemTodayQuery,
    pollInterval
  });


  const [count, setCount] = useState(false);

  if(past24HrsHNItemFetching && !count) return <div> fetching past 24 hrs info </div>; // past24HrsHNItemFetching

  if(past24HrsHNItemError) {  
    // https://stackoverflow.com/questions/60219418/react-js-log-all-graphql-queries-in-sentry

    const {name, message, graphQLErrors, networkError, response} = past24HrsHNItemError;
    
    if (graphQLErrors){
      graphQLErrors.map(({ message, locations, path }) =>
        Sentry.captureMessage(message)
      );
    }

    if (networkError) {
      Sentry.captureException(networkError);
    }

    // lets just show nothing if this erors
    return (<> 
      {/* error getting most recent stuff */}
      {/* {graphQLErrors && <></> <Text fontSize="lg" color="tomato">{mostRecentHNItemError}</Text> } */}
      {/* {past24HrsHNItemError ? <Text fontSize="lg" color="tomato">{past24HrsHNItemError}</Text> : null} */}
    </>
    );
  }

  const { aggregate } = past24HrsHNItemData.hn_item_aggregate;
  const  newCount = aggregate && aggregate.count;
    
  if(newCount !== count){
    setCount(newCount);
  }

  return (
    <Text> <b> {count} </b> total items (posts, comments, etc.) processed in the past 24 hrs. </Text>
  );
}
