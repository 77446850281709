import { useRef } from 'react';
import { useRouter } from 'next/router';

// https://next-auth.js.org/configuration/pages#credentials-sign-in
import { useForm } from 'react-hook-form';

import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  SimpleGrid,
  Text
} from '@chakra-ui/core';

import { ArrowForwardIcon } from '@chakra-ui/icons';

export default function SignInForm({ csrfToken }){
// https://nextjs.org/docs/routing/dynamic-routes
// https://stackoverflow.com/questions/43862600/how-can-i-get-query-string-parameters-from-the-url-in-next-js
  const router = useRouter();
  const { error: urlError } = router.query;

  const { handleSubmit, errors, register, formState } = useForm();

  function validateEmail(value) {
    let error;
    if (!value) {
      error = 'Email is required';
    }
    return error || true;
  }


  let formEl = useRef(null);
  // eslint-disable-next-line no-unused-vars
  function onSubmit(values) {
  // https://stackoverflow.com/questions/63015960/react-trigger-form-submit-using-useref
    formEl.current && formEl.current.submit();
  }
  return (
    <SimpleGrid columns={[1]} spacing="24px">

      {urlError ? <Text fontSize="lg" color="tomato">{urlError}</Text> : null}

      <form
        ref={formEl}
        method="post"
        action="/api/auth/callback/credentials"
      >
        <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
        <FormControl isInvalid={errors.email}>
          <FormLabel htmlFor="email">Email (for magic email login)</FormLabel>
          <Input
            name="email"
            placeholder="test@example.com"
            ref={register({ validate: validateEmail })}
          />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <br />
        <FormControl isInvalid={errors.name}>
          <FormLabel htmlFor="name">HN Username (optional if already signed up)</FormLabel>
          <Input name="name" placeholder="pg" />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
        <Button
          mt={4}
          onClick={handleSubmit(onSubmit)}
          colorScheme="teal"
          isLoading={formState.isSubmitting}
          rightIcon={<ArrowForwardIcon />}
        >
            SignUp / LogIn
        </Button>
      </form>
    </SimpleGrid>

  );
}